import React from "react"
// import { Link } from "gatsby"
import { EconomicCalenderQuery } from "../../prismic/staticQueries"
import { connect, useSelector } from "react-redux"

import Layout from "../../components/layout"
import {
  BreadCrumbBanner,
  SubscriptionPanel,
  PrismicRichText,
} from "../../components/shared"

// import { Tab, Tabs, Row, Nav, Col } from "react-bootstrap"
const OurMission = () => {
  const language = useSelector(state => state.language)
  const {
    banner_image,
    page_heading,
    page_description,
    page_description1,
  } = EconomicCalenderQuery(language)
  return (
    <>
      <Layout>
        <BreadCrumbBanner heading={page_heading} banner_image={banner_image} />

        <section
          className="team-profile p-top-120"
          style={{ background: "#fbfbfd" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="team-details">
                  <div className="m-bottom-30">
                    <h4 className="color-primary">{page_heading[0].text}</h4>
                  </div>
                  <PrismicRichText data={page_description} />
                </div>
              </div>
            </div>
          </div>
          <br />
        </section>

        <section
          className="p-bottom-80"
          style={{ background: "linear-gradient(to bottom, #fbfbfd, #f7f7f7)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="m-bottom-65">
                  <div
                    className="divider text-center"
                    style={{ height: "750px" }}
                  >
                    {/* <script type="text/javascript" src="https://c.mql5.com/js/widgets/calendar/widget.js?6"></script> */}
                    <iframe
                      title="economic calender"
                      scrolling="no"
                      allowtransparency="true"
                      frameBorder="0"
                      width="100%"
                      height="100%"
                      src="https://www.mql5.com/en/economic-calendar/widget?mode=2&amp;utm_source=accuindex.com"
                    ></iframe>
                    <div id="economicCalendarWidget"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="team-profile p-top-120"
          style={{ background: "#fbfbfd" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="team-details">
                  <PrismicRichText data={page_description1} />
                </div>
              </div>
            </div>
          </div>
          <br />
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(OurMission)
